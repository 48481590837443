import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Contate o Admina"
          subheader='Sempre estamos afim de um bate papo, uma colaboração, uma live ou podcast, um artigo, um benchmark, uma entrevista, um meetup e qualquer coisa assim.'
        />
        <Divider />
        <ContactForm />
      </Main>
{/*       <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar> */}
    </Stack>
  </Layout>
)

export default PageContact
